import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { parseDate } from 'src/app/utilities/date.utils';

export interface ProductPricingByDateDto {
    data: {
        date: string;
        default_allotment: number;
        options: {
            option_id: string;
            allotment: number;
            parent_id: string;
            actualprice: string;
            cross_price: string;
        }[];
    }[];
}

export interface ProductPricingByDate {
    date: NgbDateStruct;
    dateAsString: string;
    defaultAllotment: number;
    options: {
        optionId: number;
        allotment: number;
        price: number;
        priceBeforeDiscount: number;
        parentId: number;
    }[];
}

export const transformProductPricingByDateDtoToPricingByDate = (
    productPricingByDateDto: ProductPricingByDateDto
): ProductPricingByDate[] => {
    const result: ProductPricingByDate[] = [];
    productPricingByDateDto.data.forEach((data) => {
        result.push({
            date: parseDate(data.date),
            dateAsString: data.date,
            defaultAllotment: data.default_allotment,
            options: data.options.map((option) => ({
                optionId: Number(option.option_id),
                allotment: option.allotment,
                price: Number(option.actualprice),
                priceBeforeDiscount: Number(option.cross_price),
                parentId: Number(option.parent_id),
            })),
        });
    });
    return result;
};
