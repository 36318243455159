<div class="pdbc-activity-container">
    <div
        *ngFor="
            let service of productDetails.serviceOptionCategories;
            let k = index
        "
    >
        <h4 class="pdbc-activity-name">{{ service.name }}</h4>
        <div
            class="pdbc-activity-service-option"
            *ngFor="let option of service.options; let i = index"
        >
            <div class="pdbc-activity-service-details">
                <div class="pdbc-activity-service-name">{{ option.name }}</div>
                <div>{{ option.ageGroup }}</div>
            </div>
            <md-counter
                [maxValue]="
                    option.allotment >= 0 ? option.allotment : option.maxPax
                "
                [startValue]="0"
                (valueChanged)="onQuantityChanged(option, $event, k, i)"
            />
            <div class="pdbc-activity-service-prc">
                <div class="pdbc-activity-service-price">
                    {{
                        option.price === 0
                            ? ("FREE" | translate)
                            : (option.price
                              | currency
                                  : currencySymbol
                                  : "symbol"
                                  : "1.0-2"
                                  : locale)
                    }}
                </div>
                <div
                    *ngIf="option.price !== 0"
                    class="pdbc-activity-service-full-price"
                >
                    {{
                        option.fullPrice
                            | currency
                                : currencySymbol
                                : "symbol"
                                : "1.0-2"
                                : locale
                    }}
                </div>
            </div>
        </div>
    </div>
</div>
